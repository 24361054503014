
  import { defineComponent } from 'vue'
  import { useMeta } from 'vue-meta'
  import { useI18n } from 'vue-i18n'
  import SchoolChoiceSection from '@/components/sections/SchoolChoiceSection.vue'
  import Header from '@/components/sections/Header.vue'
  
  export default defineComponent({
    setup () {
      const { t } = useI18n({ useScope: 'global' })
      useMeta({
      })
    },
    name: 'SchoolChoice',
    mounted () {
    },
    components: {
      SchoolChoiceSection,
      Header
    }
  })
  